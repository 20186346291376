import React, {lazy, Suspense, useContext} from 'react'
import {Route, Switch} from 'react-router-dom'
import {observer} from 'mobx-react'
import Preloader from './components/Preloader/Preloader'
import GuardedRoute from './components/GuardedRoute/GuardedRoute'
import StoreContext from './stores/StoreContext'
import AboutPage from './pages/common/AboutPage/AboutPage'
import PartnersPage from './pages/common/PartnersPage/PartnersPage'
import DeliveryPage from './pages/common/DeliveryPage/DeliveryPage'
import PrivacyPage from './pages/common/PrivacyPage/PrivacyPage'
import {default as ReturnsInfoPage} from './pages/common/ReturnsPage/ReturnsPage'

const AuthPage = lazy(() => import('./pages/AuthPage/AuthPage'))
const RegistrationPage = lazy(() => import('./pages/RegistrationPage/RegistrationPage'))

const MainPage = lazy(() => import('./pages/MainPage/MainPage'))
const ProfilePage = lazy(() => import('./pages/ProfilePage/ProfilePage'))
const DocumentsPage = lazy(() => import('./pages/DocumentsPage/DocumentsPage'))
const DocumentUpdPage = lazy(() => import('./pages/DocumentUpdPage/DocumentUpdPage'))
const DocumentUkdPage = lazy(() => import('./pages/DocumentUkdPage/DocumentUkdPage'))
const OrdersPage = lazy(() => import('./pages/OrdersPage/OrdersPage'))
const OrderPage = lazy(() => import('./pages/OrderPage/OrderPage'))
const ReturnsPage = lazy(() => import('./pages/ReturnsPage/ReturnsPage'))
const CreateReturnPage = lazy(() => import('./pages/CreateReturnPage/CreateReturnPage'))
const ReturnRequestPage = lazy(() => import('./pages/ReturnRequestPage/ReturnRequestPage'))
const CartPage = lazy(() => import('./pages/CartPage/CartPage'))
const CheckoutPage = lazy(() => import('./pages/CheckoutPage/CheckoutPage'))
const CartsListPage = lazy(() => import('./pages/CartsListPage/CartsListPage'))
const CatalogPage = lazy(() => import('./pages/CatalogPage/CatalogPage'))
const SearchPage = lazy(() => import('./pages/SearchPage/SearchPage'))
const SearchResultsPage = lazy(() => import('./pages/SearchResultsPage/SearchResultsPage'))
const SearchResultsProductsPage = lazy(() => import('./pages/SearchResultsProductsPage/SearchResultsProductsPage'))
const PriceListsPage = lazy(() => import('./pages/PriceListsPage/PriceListsPage'))
const SellerOrders = lazy(() => import('./pages/SellerOrdersPage/SellerOrdersPage'))
const SellerOrder = lazy(() => import('./pages/SellerOrderPage/SellerOrderPage'))
const SellerShipmentPage = lazy(() => import('./pages/SellerShipmentPage/SellerShipmentPage'))
const SellerReturnOrdersPage = lazy(() => import('./pages/SellerReturnOrdersPage/SellerReturnOrdersPage'))
const SellerReturnsPage = lazy(() => import('./pages/SellerReturnsPage/SellerReturnsPage'))
const SellerReturnPage = lazy(() => import('./pages/SellerReturnPage/SellerReturnPage'))
const SellerReturnOrderPage = lazy(() => import('./pages/SellerReturnOrderPage/SellerReturnOrderPage'))
const SellerDocumentsPage = lazy(() => import('./pages/SellerDocumentsPage/SellerDocumentsPage'))
const SellerDocUploadPage = lazy(() => import('./pages/SellerDocUploadPage/SellerDocUploadPage'))
// const SellerCreateUpdPage = lazy(() => import('./pages/SellerCreateUpdPage/SellerCreateUpdPage'))
const StatsPage = lazy(() => import('./pages/StatsPage/StatsPage'))
const NotFoundPage = lazy(() => import('./pages/NotFoundPage/NotFoundPage'))
const InfoPage = lazy(() => import('./pages/InfoPage/InfoPage'))

const Routes = observer(() => {
    const stores = useContext(StoreContext)

    if (process.env.REACT_APP_VERSION === 'client') {
        return (
            <Suspense fallback={<Preloader/>}>
                <Switch>
                    <Route exact path="/" component={MainPage}/>

                    <Route exact path="/auth" component={AuthPage}/>
                    <Route path="/registration" component={RegistrationPage}/>

                    <Route exact path="/personal/documents" component={DocumentsPage}/>
                    <Route exact path="/personal/documents/:id" component={DocumentUpdPage}/>
                    <Route exact path="/personal/documents/:id/:id" component={DocumentUkdPage}/>

                    <GuardedRoute exact path="/personal/orders" component={OrdersPage}
                                  auth={stores?.personalStore.authorized || false}/>
                    <GuardedRoute exact path="/personal/orders/detail/:id" component={OrderPage}
                                  auth={stores?.personalStore.authorized || false}/>

                    <Route exact path="/personal/returns" component={ReturnsPage}/>
                    <Route exact path="/personal/returns/create" component={CreateReturnPage}/>
                    <Route exact path="/personal/returns/:id" component={ReturnRequestPage}/>

                    <GuardedRoute path="/personal" component={ProfilePage}
                                  auth={stores?.personalStore.authorized || false}/>

                    <Route exact path="/cart" component={CartPage}/>
                    <Route exact path="/carts/:cart" component={CartPage}/>
                    <Route exact path="/carts" component={CartsListPage}/>

                    <Route path="/checkout" component={CheckoutPage}/>

                    {/*<Route exact path='/catalog' component={CatalogPage}/>*/}

                    <Route exact path="/search" component={SearchPage}/>
                    <Route exact path="/catalog/" component={SearchResultsPage}/>
                    <Route exact path="/catalog/:article" component={SearchResultsProductsPage}/>
                    <Route exact path="/catalog/products" component={SearchResultsProductsPage}/>

                    <Route exact path="/info/:code" component={InfoPage}/>

                    <Route exact path="/loading"><Preloader fixed/></Route>
                    <UniversalRoutes/>
                    <Route component={NotFoundPage}/>
                </Switch>
            </Suspense>
        )
    }
    if (process.env.REACT_APP_VERSION === 'provider') {
        return (
            <Suspense fallback={<Preloader/>}>
                <Switch>

                    <Route exact path="/" component={AuthPage}/>
                    <Route exact path="/auth" component={AuthPage}/>
                    <GuardedRoute exact path="/seller/price-lists" component={PriceListsPage}
                                  auth={stores?.personalStore.authorized || false}/>
                    <GuardedRoute exact path="/seller/orders" component={SellerOrders}
                                  auth={stores?.personalStore.authorized || false}/>
                    <GuardedRoute exact path="/seller/orders/:id" component={SellerOrder}
                                  auth={stores?.personalStore.authorized || false}/>
                    <GuardedRoute exact path="/seller/shipment" component={SellerShipmentPage}
                                  auth={stores?.personalStore.authorized || false}/>
                    <GuardedRoute exact path="/seller/return-orders" component={SellerReturnOrdersPage}
                                  auth={stores?.personalStore.authorized || false}/>
                    <GuardedRoute exact path="/seller/return-orders/:id" component={SellerReturnOrderPage}
                                  auth={stores?.personalStore.authorized || false}/>
                    <GuardedRoute exact path="/seller/returns/" component={SellerReturnsPage}
                                  auth={stores?.personalStore.authorized || false}/>
                    <GuardedRoute exact path="/seller/returns/:id" component={SellerReturnPage}
                                  auth={stores?.personalStore.authorized || false}/>
                    <GuardedRoute exact path="/seller/documents/" component={SellerDocumentsPage}
                                  auth={stores?.personalStore.authorized || false}/>
                    <GuardedRoute exact path="/seller/documents/upload" component={SellerDocUploadPage}
                                  auth={stores?.personalStore.authorized || false}/>
                    {/*<GuardedRoute exact path="/seller/documents/create" component={SellerCreateUpdPage}*/}
                    {/*              auth={stores?.personalStore.authorized || false}/>*/}
                    <GuardedRoute exact path="/seller/stats/" component={StatsPage}
                                  auth={stores?.personalStore.authorized || false}/>
                    <GuardedRoute path="/seller/" component={ProfilePage}
                                  auth={stores?.personalStore.authorized || false}/>
                    <UniversalRoutes/>
                </Switch>
            </Suspense>
        )
    }
    if (process.env.REACT_APP_VERSION === 'admin') {
        return (
            <Suspense fallback={<Preloader/>}>
                <Switch>
                    <Route exact path="/" component={() => <p>Админ 🤩</p>}/>
                    <UniversalRoutes/>
                </Switch>
            </Suspense>
        )
    }
    return null
})

const UniversalRoutes = () => {
    return (
        <Switch>
            <Route path="/about" component={AboutPage}/>
            <Route path="/partners" component={PartnersPage}/>
            <Route path="/delivery" component={DeliveryPage}/>
            <Route path="/privacy" component={PrivacyPage}/>
            <Route path="/returns-info" component={ReturnsInfoPage}/>
        </Switch>
    )
}

export default Routes
