import DocumentsStore from './DocumentsStore'
import OrdersStore from './OrdersStore'
import OrderStore from './OrderStore'
import ReturnsStore from './ReturnsStore'
import StaticStore from './StaticStore'
import DocumentUpdStore from './DocumentUpdStore'
import DocumentUkdStore from './DocumentUkdStore'
import CreateReturnStore from './CreateReturnStore'
import ReturnRequestStore from './ReturnRequestStore'
import CartStore from './CartStore'
import HeaderStore from './HeaderStore'
import ProfileStore from "./ProfileStore";
import CatalogStore from './CatalogStore'
import PersonalStore from './PersonalStore'
import SearchStore from './SearchStore'
import SearchResultsStore from './SearchResultsStore'
import NotificationsStore from './NotificationsStore'
import PriceListsStore from './PriceListsStore'
import SellerOrdersStore from './SellerOrdersStore'
import SellerOrderStore from './SellerOrderStore'
import SellerShipmentStore from './SellerShipmentStore'
import SellerReturnOrdersStore from './SellerReturnOrdersStore'
import SellerReturnOrderStore from './SellerReturnOrderStore'
import SellerDocumentsStore from './SellerDocumentsStore'
import SellerUploadDocumentStore from './SellerUploadDocumentStore'
import SellerReturnsStore from './SellerReturnsStore'
import SellerReturnStore from './SellerReturnStore'
import StatsStore from './StatsStore'
import CheckoutStore from './CheckoutStore'

class RootStore {
    headerStore: HeaderStore
    documentsStore: DocumentsStore
    documentUpdStore: DocumentUpdStore
    documentUkdStore: DocumentUkdStore
    ordersStore: OrdersStore
    orderStore: OrderStore
    returnsStore: ReturnsStore
    createReturnStore: CreateReturnStore
    returnRequestStore: ReturnRequestStore
    cartStore: CartStore
    profileStore: ProfileStore
    catalogStore: CatalogStore
    personalStore: PersonalStore
    staticStore: StaticStore
    searchStore: SearchStore
    searchResultsStore: SearchResultsStore
    notificationsStore: NotificationsStore
    priceListsStore: PriceListsStore
    sellerOrdersStore: SellerOrdersStore
    sellerOrderStore: SellerOrderStore
    sellerShipmentStore: SellerShipmentStore
    sellerReturnOrdersStore: SellerReturnOrdersStore
    sellerReturnOrderStore: SellerReturnOrderStore
    sellerDocumentsStore: SellerDocumentsStore
    sellerUploadDocumentStore: SellerUploadDocumentStore
    sellerReturnsStore: SellerReturnsStore
    sellerReturnStore: SellerReturnStore
    statsStore: StatsStore
    checkoutStore: CheckoutStore

    constructor() {
        this.headerStore = new HeaderStore()
        this.documentsStore = new DocumentsStore()
        this.documentUpdStore = new DocumentUpdStore()
        this.documentUkdStore = new DocumentUkdStore()
        this.ordersStore = new OrdersStore()
        this.orderStore = new OrderStore()
        this.returnsStore = new ReturnsStore()
        this.createReturnStore = new CreateReturnStore()
        this.returnRequestStore = new ReturnRequestStore()
        this.cartStore = new CartStore()
        this.profileStore = new ProfileStore()
        this.catalogStore = new CatalogStore()
        this.personalStore = new PersonalStore(this)
        this.staticStore = new StaticStore()
        this.searchStore = new SearchStore()
        this.searchResultsStore = new SearchResultsStore(this)
        this.notificationsStore = new NotificationsStore()
        this.priceListsStore = new PriceListsStore()
        this.sellerOrdersStore = new SellerOrdersStore()
        this.sellerOrderStore = new SellerOrderStore()
        this.sellerShipmentStore = new SellerShipmentStore()
        this.sellerReturnOrdersStore = new SellerReturnOrdersStore()
        this.sellerReturnOrderStore = new SellerReturnOrderStore()
        this.sellerDocumentsStore = new SellerDocumentsStore()
        this.sellerUploadDocumentStore = new SellerUploadDocumentStore()
        this.sellerReturnsStore = new SellerReturnsStore()
        this.sellerReturnStore = new SellerReturnStore()
        this.statsStore = new StatsStore()
        this.checkoutStore = new CheckoutStore()
    }
}

export default RootStore
